<template>
  <div class="commodity-inventory-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="warehouseClick">
          <span>{{ warehouseComputed }}</span>
          <van-icon name="arrow-down" />
        </div>

        <div class="filter-item"
             @click="commodityTypeClick">
          <span>{{ commodityTypeComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <van-popup v-model="showWarehouse"
                 round
                 position="bottom">
        <custom-popup title="仓库"
                      value-key="name"
                      :columns="warehouseColumns"
                      @cancel="showWarehouse = false"
                      @confirm="warehouseConfirm" />
      </van-popup>
      <van-popup v-model="showCommodityType"
                 round
                 position="bottom">
        <van-radio-group v-model="filter.commodityType">
          <custom-popup title="物品类别"
                        :columns="commodityTypeColumns"
                        value-key="name"
                        :select-icon='false'
                        @cancel="showCommodityType = false"
                        @confirm="radioChange">
            <template #option="{option}">
              <div class="commodity-type">
                <van-radio :name="option.id"
                           v-if="option.name">{{ option.name }}</van-radio>
              </div>
            </template>
          </custom-popup>
        </van-radio-group>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  ref="list"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemBtn(item.stockItemId)">
            <item-card :title="item.stockItemName">
              <div class="content">
                <div class="content-row">
                  <div class="content-item"
                       style="width:100%">
                    <span class="name">规格型号:</span>
                    <span>{{ item.specifications }}</span>
                  </div>
                </div>

                <div class="content-row">
                  <div class="content-item">
                    <span class="name">均价:</span>
                    <span>{{ item.unitPrice | numberToFixed }}</span>
                  </div>
                  <div class="content-item">
                    <span class="name">金额:</span>
                    <span>{{ (item.unitPrice * item.inventoryNumber) | numberToFixed }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">存放仓库:</span>
                    <span>{{ item.warehouseName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">货位:</span>
                    <span>{{item.stockSite }}--{{ item.stockRackCode }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">商品条码:</span>
                    <span>{{ item.barcode }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">备注:</span>
                    <span>{{ item.remark }}</span>
                  </div>
                </div>
              </div>
              <template #footer>
                <div class="item-footer">
                  <div class="item-footer__content"
                       :class="[item.inventoryNumber < item.inventoryLowerLimit ? 'insufficient' : 'sufficient']">
                    <div class="item-footer__content__name">库存数量</div>
                    <div>{{ item.inventoryNumber }}</div>
                  </div>
                  <div class="item-footer__content">
                    <div class="item-footer__content__name">库存预警值</div>
                    <div>{{ item.inventoryUpperLimit }}</div>
                  </div>
                  <!-- <div class="item-footer__content">
                    <div class="item-footer__content__name">库存下限</div>
                    <div>{{ item.inventoryLowerLimit }}</div>
                  </div> -->
                </div>
              </template>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <div class="action">
      <div class="btn dynamic"
           @click="inventory('DYNAMIC')">
        <div class="btn-content">动</div>
      </div>
      <div class="btn static"
           @click="inventory('STATIC')">
        <div class="btn-content">静</div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityInventoryList.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { find, flatMap } from "lodash-es";
export default {
  name: "CommodityWarehousingList",
  data() {
    return {
      searchType: "stockItemName",
      searchTypeOptions: [
        { text: "商品名称", value: "stockItemName" },
        { text: "商品条码", value: "barcode" }
      ],
      searchContent: "",

      filter: {
        warehouseId: null,
        commodityType: null
      },
      minDate: this.$moment().subtract(0.5, "y"),

      showDate: false,
      showStartDate: false,
      showEndDate: false,
      showWarehouse: false,
      warehouseColumns: [],
      showCommodityType: false,
      commodityTypeColumns: [],
      showWarehousingType: false,
      warehousingTypeColumns: [],

      list: [],
      listStatus: {
        loading: true,
        finished: false
      },
      page: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "仓库名称";
      let item = find(this.warehouseColumns, item => item.id == this.filter.warehouseId);
      return item ? item.name : "仓库名称";
    },
    commodityTypeComputed() {
      if (!this.commodityTypeColumns.length) return "物品类别";
      let item = find(this.flatCommodityType, item => item.id == this.filter.commodityType);
      return item ? item.name : "所有类别";
    }
  },
  methods: {
    async getStockItemType() {
      let ret = await this.$api.getStockItemType();
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      data.forEach(item => {
        if (item.stockItemCategorys.length) {
          item.children = item.stockItemCategorys;
        } else {
          item.children = [{ name: "", id: "" }];
        }
      });
      this.commodityTypeColumns = [{ id: "", name: "所有类别", children: [{ name: "" }] }, ...data];
      this.flatCommodityType = flatMap(data, item => {
        return [item, ...item.stockItemCategorys];
      });
    },
    async getCommodityInventoryList(sign = false) {
      let params = {
        warehouseId: this.filter.warehouseId ? this.filter.warehouseId : null,
        stockItemCategoryId: this.filter.commodityType ? this.filter.commodityType : null,
        [this.searchType]: this.searchContent ? this.searchContent : null,
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize
      };

      let ret = await this.$api.getInventoryList({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_KEEPER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = [{ id: "", name: "全部仓库" }, ...ret.data];
    },
    onSearch() {
      this.getInitialData();
    },
    warehouseClick() {
      this.showWarehouse = true;
    },
    commodityTypeClick() {
      this.showCommodityType = true;
    },
    switchChange() {
      this.getInitialData();
    },
    radioChange() {
      this.showCommodityType = false;
      this.getInitialData();
    },
    warehouseConfirm(arg) {
      this.filter.warehouseId = arg.id;
      this.showWarehouse = false;
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getCommodityInventoryList();
    },
    addWarehousing() {
      this.$router.push({
        name: "addCommodityWarehousing"
      });
    },
    onLoad() {
      this.getCommodityInventoryList(true);
    },
    inventory(type) {
      if (!this.filter.warehouseId) {
        return this.$utils.warning("请先选择仓库");
      }
      let inventoryData = JSON.stringify(this.list) || [];
      sessionStorage.setItem("inventoryData", inventoryData);
      this.$router.push({
        name: "commodityInventory",
        query: {
          type
        }
      });
    },
    itemBtn(id) {
      this.$router.push({
        name: "commodityDetail",
        params: {
          id
        }
      });
    },
    init() {
      this.getInitialData();
    }
  },
  created() {
    this.getWarehouseList();
    this.getStockItemType();
    this.getCommodityInventoryList();
  }
};
</script>

<style></style>
